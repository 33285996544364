import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: "http://192.168.1.25:8000"
  // baseURL: 'https://api.dev-zelos.de',
  baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL: 'https://3b56-2a0d-3344-161b-6e10-8c43-aa32-1a5d-d19.ngrok-free.ap',
  timeout: 120000,
  // headers: {'X-Custom-Header': 'foobar'}
})

const axiosFileIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://api.dev-zelos.de',
  baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL: 'https://3b56-2a0d-3344-161b-6e10-8c43-aa32-1a5d-d19.ngrok-free.ap',

  timeout: 600000,
  headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer johndoe@example.com' },
})

Vue.prototype.$http = axiosIns
Vue.prototype.$api = data => axiosIns.post(`/api/?${data.entity}`, { a: data })
Vue.prototype.$upload = data => axiosFileIns.post('/uploadxlsx/', data)

export default axiosIns

export const http = Vue.prototype.$http
export const api = Vue.prototype.$api
export const upload = Vue.prototype.$upload
